import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ SUBSCRIPTIONS ++++++++ //

//SUBSCRIPTIONS ALL
export const getSubscriptionAll = async () => {
  const url = `${BASE_URL}/api/v2/suscription/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// SUBSCRIPTIONS CREATE
export const createSubscription = async (requestData) => {
  const url = `${BASE_URL}/api/v2/suscription`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// SUBSCRIPTIONS UPDATE
export const updateSubscription = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/suscription/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// SUBSCRIPTIONS GET ONE
export const getSubscriptionById = async (id) => {
  const url = `${BASE_URL}/api/v2/suscription/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// SUBSCRIPTIONS DELETE
export const deleteSubscriptionById = async (id) => {
  const url = `${BASE_URL}/api/v2/suscription/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
