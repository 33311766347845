import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ COUPONS ++++++++ //

// COUPONS ALL
export const getCouponAll = async () => {
  const url = `${BASE_URL}/api/v2/coupon/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// COUPONS CREATE
export const createCoupon = async (requestData) => {
  const url = `${BASE_URL}/api/v2/coupon`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// COUPONS UPDATE
export const updateCouponById = async (idCoupon, updateData) => {
  const url = `${BASE_URL}/api/v2/coupon/${idCoupon}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// COUPONS GET ONE
export const getCouponById = async (idCoupon) => {
  const url = `${BASE_URL}/api/v2/coupon/${idCoupon}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// COUPONS DELETE
export const deleteCouponById = async (idCoupon) => {
  const url = `${BASE_URL}/api/v2/coupon/${idCoupon}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
