import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
// Redux
// import { Provider  } from "react-redux";
// import { configureStore } from "./redux/Store";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./store";
import "./data";
// Redux Gate
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import Spinner from "./views/spinner/Spinner";
import { lazy } from "react";
import "./assets/global/global.css";
import "apexcharts/dist/apexcharts.css";
import { initializationService, nextService } from "./apis/pakup-services";
import * as Sentry from "@sentry/react";


const initalization = async () => {


  Sentry.init({
    dsn: "https://ba43651be42e95ea8b48670a1f3ca613@o4505270315778048.ingest.us.sentry.io/4507385776832512",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


  try {
    let result = await initializationService();
    localStorage.setItem("session", result.init);
    let publickey = await nextService({ data: "string" });
    localStorage.setItem("out", publickey.access_token);

    const App = lazy(() => import("./App"));
    ReactDOM.render(
      <ReduxProvider store={store}>
        <Suspense fallback={<Spinner />}>
          <HashRouter>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </HashRouter>
        </Suspense>
      </ReduxProvider>,
      document.getElementById("root")
    );
  } catch (error) {
    console.error("Initialization", error);
  }
};

initalization();

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
