import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING TYPES ++++++++ //

//PARKING ALL TYPES
export const getParkingTypes = async () => {
  const url = `${BASE_URL}/api/v2/parking/type/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PARKING TYPE CREATE
export const createParkingType = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/type`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// PARKING TYPE UPDATE
export const updateParkingTypeById = async (idParkingType, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/type/${idParkingType}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// PARKING TYPE GET ONE
export const getParkingTypeById = async (idParkingType) => {
  const url = `${BASE_URL}/api/v2/parking/type/${idParkingType}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PARKING TYPE DELETE
export const deleteParkingTypeById = async (idParkingType) => {
  const url = `${BASE_URL}/api/v2/parking/type/${idParkingType}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
