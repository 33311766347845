import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PROFILE ++++++++ //

// PROFILE ALL
export const getProfileAll = async () => {
  const url = `${BASE_URL}/api/v2/profile/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PROFILE CREATE
export const createProfile = async (requestData) => {
  const url = `${BASE_URL}/api/v2/profile`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// PROFILE UPDATE
export const updateProfileById = async (idProfile, updateData) => {
  const url = `${BASE_URL}/api/v2/profile/${idProfile}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// PROFILE GET
export const getProfileById = async (idProfile) => {
  const url = `${BASE_URL}/api/v2/profile/${idProfile}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PROFILE DELETE
export const deleteProfileById = async (idProfile) => {
  const url = `${BASE_URL}/api/v2/profile/${idProfile}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
