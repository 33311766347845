import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING STATUS ++++++++ //

//PARKING ALL STATUS
export const getParkingStatus = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/status/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PARKING STATUS CREATE
export const createParkingStatus = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/status`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// PARKING STATUS UPDATE
export const updateParkingStatusById = async (idParkingstatus, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/status/${idParkingstatus}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// PARKING STATUS GET ONE
export const getParkingStatusById = async (idParkingstatus) => {
  const url = `${BASE_URL}/api/v2/parking/status/${idParkingstatus}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PARKING STATUS DELETE
export const deleteParkingStatusById = async (idParkingstatus) => {
  const url = `${BASE_URL}/api/v2/parking/status/${idParkingstatus}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
