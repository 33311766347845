import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "reduxjs-toolkit-persist";
import {
  fetchCreateParkingMetadata,
  fetchUpdateParkingMetadata,
} from "../../thunks/parking-metadata";

// Initial state
const initialState = {
  _parkingMetadata: null,
  status: "idle",
};

// ==============================|| SLICE - PARKING ||============================== //

const parkingMetadataSlice = createSlice({
  name: "parkingMetadata",
  initialState,
  reducers: {
    // 1- Reset _parking state
    resetParkingStateThree(state, action) {
      // Mapping this state...
      state._parkingMetadata = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateParkingMetadata.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCreateParkingMetadata.fulfilled, (state, action) => {
        // console.log("parking-metadata-created-payload", action.payload);

        state._parkingMetadata = { data: action.payload };
        state.status = "succeed";
        // state._parkingMetadata = action.meta?.arg?._info;
        // state._parking.id = action.payload.id;
      })
      .addCase(fetchCreateParkingMetadata.rejected, (state, action) => {
        state.status = "error";
      });
    builder
      .addCase(fetchUpdateParkingMetadata.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUpdateParkingMetadata.fulfilled, (state, action) => {
        // console.log("parking-metadata-update-payload", action.payload);

        state.status = "succeed";
        state._parkingMetadata = { data: action.payload };
        // state._parkingMetadata = action.meta.arg._info;
        // state._parking.id = action.payload.id;
      })
      .addCase(fetchUpdateParkingMetadata.rejected, (state, action) => {
        state.status = "error";
      })

      .addCase(PURGE, (state) => {
        state._parkingMetadata = null;
        state.status = "idle";
      });
  },
});

export default parkingMetadataSlice.reducer;

export const { resetParkingStateThree } = parkingMetadataSlice.actions;
