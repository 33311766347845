// Adapting parkingLocation update response Data Object
export const updateParkingLocationInterceptor = (response) => {
  let data = response;
  let formattedResponse = {
    direction: data?.direction,
    city: data?.city?.id,
    latitude: data?.latitude,
    longitude: data?.longitude,
    photo: data?.photo,
  };

  return formattedResponse;
};
