import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createParkingMetadata,
  updateParkingMetadataByParkingId,
} from "../../../../apis/pakup-services";

// Set thunks!

export const fetchCreateParkingMetadata = createAsyncThunk(
  "parking/fetchCreateParkingMetadata",
  async (arg, { getState }) => {
    // console.log("thunk-create-parking-metadata-args", arg);
    const { _id, info_request } = arg;
    // const state = getState();
    let response;
    // let info_request; ---> Apply adapters
    try {
      response = await createParkingMetadata(_id, info_request);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-create-parking-metadata", error);
    }
  }
);

export const fetchUpdateParkingMetadata = createAsyncThunk(
  "parking/fetchUpdateParkingMetadata",
  async (arg, { getState }) => {
    // console.log("thunk-update-parking-metadata-args", arg);
    const { _id, info_request } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await updateParkingMetadataByParkingId(_id, info_request);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-update-parking-metadata", error);
    }
  }
);
