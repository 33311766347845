import { BASE_URL } from "../config";
import { callApiFormData } from "../utils";
import { getToken } from "./token";

// ++++++++ General ++++++++ //

// Upload images
export const uploadImage = async (image) => {
  const url = `${BASE_URL}/api/v2/general/uploadImagen`;
  const json = await callApiFormData(url, "POST", getToken(), image);
  return json;
};
