import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "reduxjs-toolkit-persist";
import {
  fetchCreateParkingLocation,
  fetchUpdateParkingLocation,
} from "../../thunks/parking-location";
import { updateParkingLocationInterceptor } from "../../../../interceptors/parkup";

// Initial state
const initialState = {
  _countryLocationId: null,
  _parkingLocation: {
    direction: "",
    city: null,
    comune: "",
    latitude: "",
    longitude: "",
    photo: "",
  },
  status: "idle",
};

// ==============================|| SLICE - PARKING ||============================== //

const parkingLocationSlice = createSlice({
  name: "parkingLocation",
  initialState,
  reducers: {
    // 1- Reset _parking state
    resetParkingLocationState(state, action) {
      // Mapping this state...
      state._parkingLocation = {
        direction: "",
        city: null,
        comune: "",
        latitude: "",
        longitude: "",
        photo: "",
      };
    },
    setLocationCountryState(state, action) {
      // console.log("set-payload", action.payload);
      // Mapping this state...
      state._countryLocationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateParkingLocation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCreateParkingLocation.fulfilled, (state, action) => {
        // console.log("parking-location-created-payload", action.payload);

        state.status = "succeed";
        state._parkingLocation = action.meta.arg._info;
        // state._parking.id = action.payload.id;
      })
      .addCase(fetchCreateParkingLocation.rejected, (state, action) => {
        state.status = "error";
      });
    builder
      .addCase(fetchUpdateParkingLocation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUpdateParkingLocation.fulfilled, (state, action) => {
        // console.log("parking-update-payload", action.payload);

        let response = updateParkingLocationInterceptor(action.payload);
        state.status = "succeed";
        state._parkingLocation = response;
      })
      .addCase(fetchUpdateParkingLocation.rejected, (state, action) => {
        state.status = "error";
      })

      .addCase(PURGE, (state) => {
        state._parkingLocation = {
          direction: "",
          city: null,
          comune: "",
          latitude: "",
          longitude: "",
          photo: "",
        };
        state.status = "idle";
      });
  },
});

export default parkingLocationSlice.reducer;

export const { resetParkingLocationState, setLocationCountryState } =
  parkingLocationSlice.actions;
