import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ BOOKING ++++++++ //

// BOOKINGS BY PARKING_ID
export const getBookingsByParkingId = async (_id) => {
  const url = `${BASE_URL}/api/v2/parking/booking/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};
