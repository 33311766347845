// Adapting profiles  response Data Object
export const profileByIdInterceptor = (dataObject) => {
  let formattedResponse;
  let access_profile = dataObject.profileInfo;
  let all_permitions = dataObject.permitionsAll.map((_permit) => {
    let _idx;
    _idx = access_profile.findIndex((_el) => _el.permition.id == _permit.id);
    if (_idx < 0) {
      return {
        id: _permit.id,
        name: _permit.name,
        path: _permit.patch,
        description: _permit.description,
        // order: _permit.order,
        isRead: false,
        isUpdate: false,
        isDelete: false,
      };
    } else {
      return {
        id: _permit.id,
        name: _permit.name,
        path: _permit.patch,
        description: _permit.description,
        // order: _permit.order,
        isRead: access_profile[_idx].isRead,
        isUpdate: access_profile[_idx].isUpdate,
        isDelete: access_profile[_idx].isDelete,
      };
    }
  });

  // console.log(all_permitions);

  formattedResponse = {
    key: dataObject.profileInfo.id || "No viene de endpoint",
    profile_name: dataObject.profileInfo.name || "No viene de endpoint",
    // profile_access
    profile_access: all_permitions,
  };

  return formattedResponse;
};
