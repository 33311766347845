import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ RASPBERRY ++++++++ //

//FORCE TO OPEN PARKING DOOR
export const forceToOpenDoor = async (id) => {
  const url = `${BASE_URL}/api/v2/raspberry/force/open/${id}`;
  const json = await callApi(url, "POST", getToken(), null);
  return json;
};

//FORCE TO CLOSE PARKING DOOR
export const forceToCloseDoor = async (id) => {
  const url = `${BASE_URL}/api/v2/raspberry/force/close/${id}`;
  const json = await callApi(url, "POST", getToken(), null);
  return json;
};
