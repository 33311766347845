import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken, getPublicToken } from "./token";

// ++++++++ AUTHENTICATION ++++++++ //

// SIGNIN
export const signin = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/signin/web`;
  const json = await callApiLogin(url, "POST", getPublicToken(), requestData);
  return json;
};

// SIGNUP
export const signup = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/signup`;
  const json = await callApiLogin(url, "POST", null, requestData);
  return json;
};

// SIGNOUT
export const signout = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/signout`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// RECOVERY
export const recoveryPassword = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/admin/recovery`;
  const json = await callApi(url, "POST", getPublicToken(), requestData);
  return json;
};

// iNITIALIZATION
export const initializationService = async () => {
  const url = `${BASE_URL}/api/v2/auth/init`;
  const json = await callApi(url, "GET", "", null);
  return json;
};

// NEXT
export const nextService = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/next`;
  const json = await callApi(url, "POST", "", requestData);
  return json;
};
