import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ BUSINESS ++++++++ //

// BUSINESS ALL
export const getBusinessAll = async () => {
  const url = `${BASE_URL}/api/v2/business/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// BUSINESS CREATE
export const createBusiness = async (requestData) => {
  const url = `${BASE_URL}/api/v2/business`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// BUSINESS UPDATE
export const updateBusinessById = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/business/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// BUSINESS GET
export const getBusinessById = async (id) => {
  const url = `${BASE_URL}/api/v2/business/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// BUSINESS DELETE
export const deleteBusinessById = async (id) => {
  const url = `${BASE_URL}/api/v2/business/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
