import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ HARDWARE ++++++++ //

// HARDWARES ALL
export const getHardwareAll = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/hardware/all`;
  const json = await callApi(url, "GET", getToken(), requestData);
  return json;
};

// HARDWARES relation to ParkingId
export const relationParkingToHardware = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/parking/hardware/${_id}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// HARDWARES remote restart
export const remoteRestartHardware = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/hardware/restart`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// HARDWARES remote open
export const remoteOpenHardware = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/hardware/open`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// HARDWARES remote close
export const remoteCloseHardware = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/hardware/close`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};