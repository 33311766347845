import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ CITIES ++++++++ //

// CITIES ALL
export const getCitiesAll = async (_idCountry) => {
  const url = `${BASE_URL}/api/v2/city/all/${_idCountry}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// CITY CREATE
export const createCity = async (_idCountry, requestData) => {
  const url = `${BASE_URL}/api/v2/city/${_idCountry}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// CITY UPDATE
export const updateCityById = async (idCity, updateData) => {
  const url = `${BASE_URL}/api/v2/city/${idCity}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// CITY GET
export const getCityById = async (idCity) => {
  const url = `${BASE_URL}/api/v2/city/${idCity}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// CITY DELETE
export const deleteCityById = async (idCity) => {
  const url = `${BASE_URL}/api/v2/city/${idCity}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
