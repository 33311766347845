import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING SCHEDULER ADVANCED++++++++ //

//PARKING SCHEDULER ADVANCED ALL
export const getAllParkingSchedulersAdvanced = async (idParking) => {
  const url = `${BASE_URL}/api/v3/parking/schedulers/all/${idParking}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING SCHEDULER CREATE ADVANCED
export const createParkingSchedulerAdvanced = async (
  idParking,
  requestData
) => {
  const url = `${BASE_URL}/api/v3/parking/schedulers/${idParking}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};
//PARKING SCHEDULER UPDATE ADVANCED
export const updateParkingSchedulerAdvanced = async (
  idParking,
  requestData
) => {
  const url = `${BASE_URL}/api/v3/parking/schedulers/${idParking}`;
  const json = await callApi(url, "PUT", getToken(), requestData);
  return json;
};

//PARKING SCHEDULER DELETE ADVANCED
export const deleteParkingSchedulerAdvanced = async (idRegister) => {
  const url = `${BASE_URL}/api/v3/parking/schedulers/${idRegister}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
