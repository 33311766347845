import { JWT_SECRET } from "../apis/config";
// Thirds
import {
  configureStore,
  // getDefaultMiddleware
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import storage from "reduxjs-toolkit-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "reduxjs-toolkit-persist";
// import { encryptTransform } from "redux-persist-transform-encrypt";

// Parkup-reducers
import reducers from "./reducers";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const logger = createLogger();

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [], //states that u want to persist
  blacklist: [
    "parking",
    "parkingLocation",
    "parkingMetadata",
    "parkingScheduler",
    "CustomizerReducer",
    "chatReducer",
    "notesReducer",
    "emailReducer",
  ], //state that u dont want to persit
  transforms: [
    // encryptTransform({
    //   secretKey: JWT_SECRET,
    //   onError: (err) => {
    //     console.log("err", err);
    //   },
    // }),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .concat(logger),
});

const { dispatch } = store;

let persistor = persistStore(store);

export { persistor, store, dispatch };
