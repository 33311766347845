import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ USERS ++++++++ //

// USERS ALL
export const getUsersAll = async () => {
  const url = `${BASE_URL}/api/v2/users/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// USERS CREATE
export const createUser = async (requestData) => {
  const url = `${BASE_URL}/api/v2/users`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// USERS UPDATE
export const updateUserById = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/users/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// USERS GET
export const getUserById = async (id) => {
  const url = `${BASE_URL}/api/v2/users/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// USER GET MY PROFILE
export const getMyProfile = async () => {
  const url = `${BASE_URL}/api/v2/users/admin/profile`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// UPDATE MY PROFILE 
export const updatePasswordAccountFn = async (requestData) => {
  const url = `${BASE_URL}/api/v2/users/admin/profile`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};


// USERS DELETE
export const deleteUserById = async (id) => {
  const url = `${BASE_URL}/api/v2/users/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};

// USERS GET
export const getAccountsByUserId = async (id) => {
  const url = `${BASE_URL}/api/v2/users/accounts/payments/admin/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// GET USER MOVEMENTS ALL BY ACCOUNT ID
export const getUsersMovementByAccountId = async (requestData) => {
  const url = `${BASE_URL}/api/v2/users/mov`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

export const apiGetAllUserSearch = async (body) => {
  const url = `${BASE_URL}/api/v2/users/search/all/admin`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};
