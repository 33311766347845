import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ TECHS ++++++++ //

//ALL TECHNOLOGIES
export const getTecnologiesAll = async (requestData) => {
  const url = `${BASE_URL}/api/v2/technology/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// TECHNOLOGIES CREATE
export const createTechnology = async (requestData) => {
  const url = `${BASE_URL}/api/v2/technology`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// TECHNOLOGIES UPDATE
export const updateTechnologyById = async (idTech, updateData) => {
  const url = `${BASE_URL}/api/v2/technology/${idTech}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// TECHNOLOGIES GET ONE
export const getTechnologyById = async (idTech) => {
  const url = `${BASE_URL}/api/v2/technology/${idTech}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// TECHNOLOGIES DELETE
export const deleteTechnologyById = async (idTech) => {
  const url = `${BASE_URL}/api/v2/technology/${idTech}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
