import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createParkingScheduler,
  updateParkingSchedulerById,
} from "../../../../apis/pakup-services";

// Set thunks!

export const fetchCreateParkingScheduler = createAsyncThunk(
  "parking/fetchCreateParkingScheduler",
  async (arg, { getState }) => {
    // console.log("thunk-create-parking-scheduler-args", arg);
    const { _id, _info } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await createParkingScheduler(_id, _info);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-create-parking-scheduler", error);
    }
  }
);

export const fetchUpdateParkingScheduler = createAsyncThunk(
  "parking/fetchUpdateParkingScheduler",
  async (arg, { getState }) => {
    // console.log("thunk-update-parking-scheduler-args", arg);
    const { _id, _info } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await updateParkingSchedulerById(_id, _info);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-update-parking-scheduler", error);
    }
  }
);
