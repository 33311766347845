import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ BUSINESS USERS ++++++++ //

// BUSINESS USERS ALL
export const getBusinessUsersAll = async (id) => {
  const url = `${BASE_URL}/api/v2/business/user/all/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// BUSINESS USERS CREATE
export const createBusinessUser = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/business/user/${_id}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// // BUSINESS USERS DELETE
export const deleteBusinessUserById = async (id) => {
  const url = `${BASE_URL}/api/v2/business/user/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
