import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createParking,
  updateParkingById,
} from "../../../../apis/pakup-services";

// Set thunks!

export const fetchCreateParking = createAsyncThunk(
  "parking/fetchCreateParking",
  async (arg, { getState }) => {
    // console.log("thunk-create-parking-args", arg);
    // const { _info } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await createParking(arg);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-create-parking", error);
    }
  }
);

export const fetchUpdateParking = createAsyncThunk(
  "parking/fetchUpdateParking",
  async (arg, { getState }) => {
    // console.log("thunk-update-parking-args", arg);
    const { _id, _info } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await updateParkingById(_id, _info);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-update-parking", error);
    }
  }
);
