import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING SCHEDULER++++++++ //

//PARKING SCHEDULER CREATE
export const createParkingScheduler = async (idParking, requestData) => {
  const url = `${BASE_URL}/api/v2/parking/schedulers/${idParking}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//PARKING SCHEDULER ALL
export const getAllParkingSchedulers = async (idParking) => {
  const url = `${BASE_URL}/api/v2/parking/schedulers/all/${idParking}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING SCHEDULER UPDATE
export const updateParkingSchedulerById = async (idScheduler, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/schedulers/${idScheduler}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

//PARKING SCHEDULER DELETE
export const deleteParkingSchedulerById = async (idScheduler) => {
  const url = `${BASE_URL}/api/v2/parking/schedulers/${idScheduler}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
