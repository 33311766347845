import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ FAQS ++++++++ //

// FAQ ALL
export const getFaqsAll = async () => {
  const url = `${BASE_URL}/api/v2/faq/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// FAQ CREATE
export const createFaq = async (requestData) => {
  const url = `${BASE_URL}/api/v2/faq`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// FAQ UPDATE
export const updateFaqById = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/faq/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// FAQ GET
export const getFaqById = async (id) => {
  const url = `${BASE_URL}/api/v2/faq/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// FAQ DELETE
export const deleteFaqById = async (id) => {
  const url = `${BASE_URL}/api/v2/faq/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
