import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ CURRENCIES ++++++++ //

// CURRENCY ALL
export const getCurrencyAll = async () => {
  const url = `${BASE_URL}/api/v2/currency/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// CURRENCY CREATE
export const createCurrency = async (requestData) => {
  const url = `${BASE_URL}/api/v2/currency`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// CURRENCY UPDATE
export const updateCurrencyById = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/currency/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// CURRENCY GET
export const getCurrencyById = async (id) => {
  const url = `${BASE_URL}/api/v2/currency/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// CURRENCY DELETE
export const deleteCurrencyById = async (id) => {
  const url = `${BASE_URL}/api/v2/currency/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
