import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ COUNTRY ++++++++ //

// COUNTRY ALL
export const getCountryAll = async () => {
  const url = `${BASE_URL}/api/v2/country/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// COUNTRY CREATE
export const createCountry = async (requestData) => {
  const url = `${BASE_URL}/api/v2/country`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// COUNTRY UPDATE
export const updateCountryById = async (idCountry, updateData) => {
  const url = `${BASE_URL}/api/v2/country/${idCountry}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// COUNTRY GET
export const getCountryById = async (idCountry) => {
  const url = `${BASE_URL}/api/v2/country/${idCountry}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// COUNTRY DELETE
export const deleteCountryById = async (idCountry) => {
  const url = `${BASE_URL}/api/v2/country/${idCountry}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
