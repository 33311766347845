import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ USER PLATES ++++++++ //

// GET USER PLATES
export const getUserPlatesAll = async (_id) => {
  const url = `${BASE_URL}/api/v2/users/car/admin/all/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};

// CREATE USER PLATES
export const createUserPlate = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/users/car/admin/${_id}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// DELETE USER PLATES
export const deleteUserPlate = async (_idUser, _idPlate) => {
  const url = `${BASE_URL}/api/v2/users/car/admin/${_idUser}/${_idPlate}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
