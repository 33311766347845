import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ ADMINISTRATOR PARKING ++++++++ //

// ADMINISTRATORS ALL
export const getAllAdministrators = async () => {
  const url = `${BASE_URL}/api/v2/administrator/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// ADMINISTRATOR BY ID
export const getAllAdministratorById = async (_id) => {
  const url = `${BASE_URL}/api/v2/administrator/${_id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};
