import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ STICKERTAGS ++++++++ //

// GET STICKERTAGS
export const getStickertagsAll = async () => {
    const url = `${BASE_URL}/api/v2/stickertag/admin`;
    const json = await callApi(url, "GET", getToken());
    return json;
  };
  
  // CREATE STICKERTAGS
  export const createStickertag = async ( requestData) => {
    const url = `${BASE_URL}/api/v2/stickertag/admin`;
    const json = await callApi(url, "POST", getToken(), requestData);
    return json;
  };
  
  // DELETE STICKERTAGS
  export const deleteStickertag = async (_id) => {
    const url = `${BASE_URL}/api/v2/stickertag/admin/${_id}`;
    const json = await callApi(url, "DELETE", getToken(), null);
    return json;
  };