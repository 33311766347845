import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ BOOKING ++++++++ //

// BOOKINGS ALL
export const  getBookingByUser = async (_id) => {
  const url = `${BASE_URL}/api/v2/booking/user/userById/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};

// BOOKING USER BY ID
export const getUserBookingById = async (_id) => {
  const url = `${BASE_URL}/api/v2/booking/user/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};

// BOOKINGS BY PARKING_ID
export const getRegistersByParking = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/parking/all/${_id}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// CREATE BOOKINGS BY USER_ID
export const createBookingUser = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/admin/${_id}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// UPDATE BOOKINGS BY USER_ID
export const updateBookingUser = async (_idBooking, _idUser, requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/admin/${_idBooking}/${_idUser}`;
  const json = await callApi(url, "PUT", getToken(), requestData);
  return json;
};

// UPDATE BOOKINGS BY USER_ID
export const deleteBookingUser = async (_idBooking, _idUser) => {
  const url = `${BASE_URL}/api/v2/booking/user/admin/${_idBooking}/${_idUser}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};

// CLOSE BOOKINGS BY BOOKING_ID
export const closeBookingUser = async (_idBooking, requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/update/admin/${_idBooking}`;
  const json = await callApi(url, "PUT", getToken(), requestData);
  return json;
};

// CREATE RECHARGE USER
export const createRechageAdmin = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/users/add/balance`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};
