import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ BOOKING ++++++++ //

// BOOKINGS ALL
export const getBookingsAll = async (requestData) => {
  const url = `${BASE_URL}/api/v2/business/all`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// USER BOOKINGS BY PARKING_ID
export const getRegistersByParking = async (_id, requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/parking/all/${_id}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// BOOKINGS STATUS ALL
export const getBookingStatusAll = async (requestData) => {
  const url = `${BASE_URL}/api/v2/booking/status/all`;
  const json = await callApi(url, "GET", getToken(), requestData);
  return json;
};


// BOOKINGS STATUS ALL
export const getBookingEvent = async (requestData) => {
  const url = `${BASE_URL}/api/v2/booking/user/get/all/event`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};
