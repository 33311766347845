// Adapting permitions  response Data Object
export const getAllPermitionInterceptor = (response) => {
  let responses = Array.isArray(response) ? response : [];
  let formattedResponse = responses.map((permition) => {
    return {
      id: permition.id,
      name: permition.name,
      path: permition.patch,
      description: permition.description,
      isRead: false,
      isUpdate: false,
      isDelete: false,
    };
  });

  return formattedResponse;
};
