import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "reduxjs-toolkit-persist";
import {
  fetchCreateParkingScheduler,
  fetchUpdateParkingScheduler,
} from "../../thunks/parking-scheduler";

// Initial state
const initialState = {
  _parkingScheduler: [],
  status: "idle",
};

// ==============================|| SLICE - PARKING SCHEDULER ||============================== //

const parkingSchedulerSlice = createSlice({
  name: "parkingScheduler",
  initialState,
  reducers: {
    // 1- Reset _parkingScheduler state
    resetParkingSchedulerState(state, action) {
      // Mapping this state...
      state._parkingScheduler = [];
    },
    // 2- set _parkingScheduler state
    setParkingSchedulerState(state, action) {
      // Mapping this state...
      state._parkingScheduler = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateParkingScheduler.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCreateParkingScheduler.fulfilled, (state, action) => {
        // console.log("parking-scheduler-created-payload", action.payload);

        state.status = "succeed";
        // state._parkingScheduler = action.meta?.arg?._info;
        // state._parking.id = action.payload.id;
      })
      .addCase(fetchCreateParkingScheduler.rejected, (state, action) => {
        state.status = "error";
      });
    builder
      .addCase(fetchUpdateParkingScheduler.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUpdateParkingScheduler.fulfilled, (state, action) => {
        // console.log("parking-scheduler-update-payload", action.payload);

        state.status = "succeed";
        // state._parkingMetadata = { data: action.payload };
        // state._parkingMetadata = action.meta.arg._info;
        // state._parking.id = action.payload.id;
      })
      .addCase(fetchUpdateParkingScheduler.rejected, (state, action) => {
        state.status = "error";
      })

      .addCase(PURGE, (state) => {
        state._parkingScheduler = [];
        state.status = "idle";
      });
  },
});

export default parkingSchedulerSlice.reducer;

export const { resetParkingSchedulerState, setParkingSchedulerState } =
  parkingSchedulerSlice.actions;
