import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKINGS ++++++++ //

//PARKING ALL
export const getAllParkings = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING CREATE
export const createParking = async (requestData) => {
  const url = `${BASE_URL}/api/v2/parking`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//PARKING GET
export const getParkingById = async (id) => {
  const url = `${BASE_URL}/api/v2/parking/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING GET
export const getParkingByAccountID = async (id) => {
  const url = `${BASE_URL}/api/v2/parking/all/map/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING GET FOR BOOKING
export const getParkingBookingByAccountID = async (id, idUser) => {
  const url = `${BASE_URL}/api/v2/parking/all/mapadmin/${id}/${idUser}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING UPDATE
export const updateParkingById = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

//PARKING DELETE
export const deleteParkingById = async (id) => {
  const url = `${BASE_URL}/api/v2/parking/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
