import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING CONTACTS++++++++ //

//PARKING CONTACTS ALL
export const getAllParkingContacts = async (idPk) => {
  const url = `${BASE_URL}/api/v2/parking/contact/all/${idPk}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING CONTACTS CREATE
export const createParkingContact = async (idPk, requestData) => {
  const url = `${BASE_URL}/api/v2/parking/contact/${idPk}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//PARKING CONTACTS UPDATE
export const updateParkingContactById = async (idCt, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/contact/${idCt}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

//PARKING CONTACTS DELETE
export const deleteParkingContactById = async (idCt) => {
  const url = `${BASE_URL}/api/v2/parking/contact/${idCt}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
