const dotenv = require("dotenv");

dotenv.config();

const env_status = process.env.REACT_APP_ENV_STATUS;

// if (!env_status) {
//   console.log(`⚠️  Couldn't find .env file  ⚠️: ${env_status}`);
// } else {
//   console.log(`⚠️  The .env file is  ⚠️: ${env_status}`);
// }

const config = {
  BASE_URL: process.env.REACT_APP_BASEURL || "https://api.parkup.cl",
  MAPS_APIKEY: process.env.REACT_APP_MAPS_APIKEY,
  JWT_SECRET:
    process.env.REACT_APP_JWT_SECRET || "j4enqvfg1dj0q8nf121w429f3b6p7i7g",
  METABASE_SITE_URL: process.env.REACT_APP_METABASE_SITE_URL,
  METABASE_SECRET_KEY: process.env.REACT_APP_METABASE_SECRET_KEY,
  META_DASH: process.env.REACT_APP_META_DASH,
  META_RESERVAS: process.env.REACT_APP_META_RESERVAS,
  META_USER: process.env.REACT_APP_META_USER,
  META_FALLIDAS: process.env.REACT_APP_META_FALLIDAS,
  META_ACTIVAS: process.env.REACT_APP_META_ACTIVAS,
  META_INGRESOS: process.env.REACT_APP_META_INGRESOS,
  META_MOVIMIENTOS: process.env.REACT_APP_META_MOVIMIENTOS,
  META_FUNDS: process.env.REACT_APP_META_FUNDS
};

const {
  BASE_URL,
  JWT_SECRET,
  MAPS_APIKEY,
  METABASE_SITE_URL,
  METABASE_SECRET_KEY,
  META_DASH,
  META_RESERVAS,
  META_USER,
  META_FALLIDAS,
  META_ACTIVAS,
  META_INGRESOS,
  META_MOVIMIENTOS,
  META_FUNDS
} = config;

// console.log("1-jwt_secret", JWT_SECRET);

//DESCYPHER
let b = 0;
let des = "";
try {
  let dat = localStorage.getItem("session");

  while (b < dat.length - 32) {
    des = des + dat.charAt(b);
    b = b + 33;
  }
} catch (error) {
  des = JWT_SECRET;
}

const DESCYPHERED = des;

// console.log("2-jwt_secret", DESCYPHER);

module.exports = {
  BASE_URL,
  JWT_SECRET: DESCYPHERED,
  MAPS_APIKEY,
  METABASE_SITE_URL,
  METABASE_SECRET_KEY,
  META_DASH,
  META_RESERVAS,
  META_USER,
  META_FALLIDAS,
  META_ACTIVAS,
  META_INGRESOS,
  META_MOVIMIENTOS,
  META_FUNDS
};
