import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING METADATA ++++++++ //

//PARKING METADATA CREATE
export const createParkingMetadata = async (parkingId, requestData) => {
  const url = `${BASE_URL}/api/v2/parking/metadata/${parkingId}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//PARKING METADATA GET ALL
export const getAllParkingMetadataByParkingId = async (
  idParking,
  updateData
) => {
  const url = `${BASE_URL}/api/v2/parking/metadata/all/${idParking}`;
  const json = await callApi(url, "GET", getToken(), updateData);
  return json;
};

//PARKING UPDATE METADATA
export const updateParkingMetadataByParkingId = async (
  idParking,
  updateData
) => {
  const url = `${BASE_URL}/api/v2/parking/metadata/${idParking}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};
