import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createParkingLocation,
  updateParkingLocationByParkingId,
} from "../../../../apis/pakup-services";

// Set thunks!

export const fetchCreateParkingLocation = createAsyncThunk(
  "parking/fetchCreateParkingLocation",
  async (arg, { getState }) => {
    // console.log("thunk-create-parkingLocation-args", arg);
    const { _id, _info } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await createParkingLocation(_id, _info);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-create-parking-location", error);
    }
  }
);

export const fetchUpdateParkingLocation = createAsyncThunk(
  "parking/fetchUpdateParkingLocation",
  async (arg, { getState }) => {
    // console.log("thunk-update-parking-location-args", arg);
    const { _id, _info } = arg;
    // const state = getState();
    let response;
    // let _info; ---> Apply adapters
    try {
      response = await updateParkingLocationByParkingId(_id, _info);
      // _info = ---> Apply interceptors
      return response;
    } catch (error) {
      console.log("thunk-update-parking-location", error);
    }
  }
);
