import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PERMITION ++++++++ //

// PERMITION ALL
export const getPermitionAll = async () => {
  const url = `${BASE_URL}/api/v2/permition/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PERMITION CREATE
export const createPermition = async (requestData) => {
  const url = `${BASE_URL}/api/v2/permition`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// PERMITION UPDATE
export const updatePermitionById = async (idPermition, updateData) => {
  const url = `${BASE_URL}/api/v2/permition/${idPermition}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

// PERMITION GET
export const getPermitionById = async (idPermition) => {
  const url = `${BASE_URL}/api/v2/permition/${idPermition}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PERMITION DELETE
export const deletePermitionById = async (idPermition) => {
  const url = `${BASE_URL}/api/v2/permition/${idPermition}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
