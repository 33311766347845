import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING LOCATION ++++++++ //

//PARKING LOCATION CREATE
export const createParkingLocation = async (parkingId, requestData) => {
  const url = `${BASE_URL}/api/v2/parking/location/${parkingId}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//PARKING LOCATION GET ONE
export const getParkingLocationByParkingId = async (idParking) => {
  const url = `${BASE_URL}/api/v2/parking/location/all/${idParking}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING LOCATION UPDATE
export const updateParkingLocationByParkingId = async (id, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/location/${id}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};
