import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ USER GARAGES ++++++++ //

// GET USER GARAGES
export const getUsersParkingGarages = async (_id) => {
  const url = `${BASE_URL}/api/v2/users/parking/getAll/associate/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};
