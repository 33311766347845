import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ BUSINESS PARKING ++++++++ //

// BUSINESS PARKING ALL
export const getBusinessParkingAll = async (id) => {
  const url = `${BASE_URL}/api/v2/parking/business/parking/all/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// BUSINESS PARKING CREATE
export const createBusinessParking = async (idB, idP, dataReq) => {
  const url = `${BASE_URL}/api/v2/parking/business/${idB}/${idP}`;
  const json = await callApi(url, "POST", getToken(), dataReq);
  return json;
};

// BUSINESS PARKING UPDATING
export const updateBusinessParking = async (idRel, dataReq) => {
  const url = `${BASE_URL}/api/v2/parking/business/${idRel}`;
  const json = await callApi(url, "PUT", getToken(), dataReq);
  return json;
};

// // BUSINESS PARKING DELETE
export const deleteBusinessParkingById = async (id) => {
  const url = `${BASE_URL}/api/v2/parking/business/parking/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
