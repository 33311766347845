import { BASE_URL } from "../config";
import { callApi, callApiInit, callApiLogin } from "../utils";
import { getToken } from "./token";

// ++++++++ PARKING SUBSCRIPTION ++++++++ //

//PARKING GENERAL SUBSCRIPTION ALL
export const getGeneralParkingSubscriptions = async () => {
  const url = `${BASE_URL}/api/v2/parking/subscription/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING SUBSCRIPTION ALL
export const getAllParkingSubscriptions = async (id) => {
  const url = `${BASE_URL}/api/v2/parking/subscription/all/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//PARKING SUBSCRIPTION CREATE
export const createParkingSubcription = async (idPk, requestData) => {
  const url = `${BASE_URL}/api/v2/parking/subscription/${idPk}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

//PARKING SUBSCRIPTION UPDATE
export const updateParkingSubscriptionById = async (idSb, updateData) => {
  const url = `${BASE_URL}/api/v2/parking/subscription/${idSb}`;
  const json = await callApi(url, "PUT", getToken(), updateData);
  return json;
};

//PARKING SUBSCRIPTION DELETE
export const deleteParkingSubscriptionById = async (idSb) => {
  const url = `${BASE_URL}/api/v2/parking/subscription/${idSb}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
