import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ BOOKING BUSINESS ++++++++ //

// BOOKING BUSINESS ALL
export const getBookingByBusinessId = async (_id) => {
  const url = `${BASE_URL}/api/v2/business/booking/${_id}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};

// BOOKING BUSINESS BY ID
