import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "reduxjs-toolkit-persist";
import { fetchCreateParking, fetchUpdateParking } from "../../thunks/parking";

// Initial state
const initialState = {
  _parking: {
    id: null,
    name: "",
    alias: "",
    place: null,
    status: null,
    type: null,
    valueminute: 0,
    freetime: 0,
    booking: {
      isBusiness: false,
      isPublic: false,
      isResident: false,
      onlySuscription: false,
      relocate: false,
      force_min: false,
    },
    technology: [
      // {
      //   id: 0,
      // },
    ],
  },
  parkingLimit: null,
  status: "idle",
};

// ==============================|| SLICE - PARKING ||============================== //

const parkingSlice = createSlice({
  name: "parking",
  initialState,
  reducers: {
    // 1- Reset _parking state
    resetParkingStateOne(state, action) {
      // Mapping this state...
      state._parking = {
        id: null,
        name: "",
        alias: "",
        place: null,
        status: null,
        type: null,
        valueminute: 0,
        freetime: 0,
        booking: {
          isBusiness: false,
          isPublic: false,
          isResident: false,
          onlySuscription: false,
          relocate: false,
          force_min: false,
        },
        technology: [
          // {
          //   id: 0,
          // },
        ],
      };
    },
    setParkingState(state, action) {
      state._parking = {
        id: null,
        name: "",
        alias: "",
        place: null,
        status: null,
        type: null,
        valueminute: 0,
        freetime: 0,
        booking: {
          isBusiness: false,
          isPublic: false,
          isResident: false,
          onlySuscription: false,
          relocate: false,
          force_min: false,
        },
        technology: [
          // {
          //   id: 0,
          // },
        ],
      };
    },
    setParkingLimit(state, action) {
      state.parkingLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateParking.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCreateParking.fulfilled, (state, action) => {
        // console.log("parking-created-payload", action.payload);

        state.status = "succeed";
        state._parking = action.meta.arg;
        state._parking.id = action.payload.id;
      })
      .addCase(fetchCreateParking.rejected, (state, action) => {
        state.status = "error";
      });
    builder
      .addCase(fetchUpdateParking.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUpdateParking.fulfilled, (state, action) => {
        // console.log("parking-update-payload", action.payload);

        state.status = "succeed";
        // state._parking = action.payload;
        state._parking = action.meta.arg._info;
        state._parking.id = action.payload.id;
      })
      .addCase(fetchUpdateParking.rejected, (state, action) => {
        state.status = "error";
      })

      .addCase(PURGE, (state) => {
        state._parking = {
          name: "",
          alias: "",
          place: null,
          status: null,
          type: null,
          valueminute: 0,
          freetime: 0,
          booking: {
            isBusiness: false,
            isPublic: false,
            isResident: false,
            onlySuscription: false,
            relocate: false,
            force_min: false,
          },
          technology: [],
        };
        state.status = "idle";
      });
  },
});

export default parkingSlice.reducer;

export const { resetParkingState, setParkingLimit } = parkingSlice.actions;
