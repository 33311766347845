// Third-party
import { combineReducers } from "@reduxjs/toolkit";
// Functionals reducers
import CustomizerReducer from "../../redux/customizer/CustomizerReducer";
import chatReducer from "../../redux/chats/ChatReducer";
import notesReducer from "../../redux/notes/NotesReducer";
import emailReducer from "../../redux/email/EmailReducer";

// Importing slices
import parking from "./slice/parking";
import parkingLocation from "./slice/parking-location";
import parkingMetadata from "./slice/parking-metadata";
import parkingScheduler from "./slice/parking-scheduler";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  parking,
  parkingLocation,
  parkingMetadata,
  parkingScheduler,
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
});

export default reducers;
