import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ USER STICKER ++++++++ //

// GET USER STICKER
export const getUserStickerAll = async (_idUser) => {
  const url = `${BASE_URL}/api/v2/stickertag/all/${_idUser}`;
  const json = await callApi(url, "GET", getToken());
  return json;
};

// CREATE USER STICKER
export const createUserSticker = async (_idUser, _idPlate, requestData) => {
  const url = `${BASE_URL}/api/v2/stickertag/${_idUser}/${_idPlate}`;
  const json = await callApi(url, "POST", getToken(), requestData);
  return json;
};

// DELETE USER STICKER
export const deleteUserSticker = async (_id) => {
  const url = `${BASE_URL}/api/v2/stickertag/${_id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
